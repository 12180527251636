import { ref } from 'vue';
import axios from '@/services/useAxios';
import { DateTime } from 'luxon';

export function useEventType() {
    const horas = ref([]);
    const event = ref(null);
    const events = ref([]);

    const getHoras = async (dayWeek, especialista = undefined) => {
        const res = await axios.get(
            // eslint-disable-next-line comma-dangle
            `/care/agenda/event-types/day-schedule?weekDay=${dayWeek}${
                especialista ? `&especialista=${especialista}` : ''
                // eslint-disable-next-line comma-dangle
            }`
        );
        if (res.status === 200) {
            // console.log(res.data);
            horas.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    // eslint-disable-next-line object-curly-newline
    const buildEvents = ({ name, types, days, timeStart, timeEnd, period, especialistaId }) => {
        const newEvent = {
            name,
            types,
            title: name.charAt(0).toUpperCase() + name.slice(1),
            period,
            especialista: especialistaId,
        };

        newEvent.schedule = days.map((d) => {
            const ts = DateTime.fromISO(timeStart);
            const te = DateTime.fromISO(timeEnd).minus({ seconds: 1 });
            return {
                weekDay: d,
                startHour: ts.hour,
                startMin: ts.minute,
                startSec: ts.second,
                endHour: te.hour,
                endMin: te.minute,
                endSec: te.second,
            };
        });

        return newEvent;
    };

    const buildFilterForSetup = (especialistaId) => {
        const filters = [];
        filters.push({
            field: 'especialista',
            value: especialistaId,
            condition: '',
            equal: 'is',
        });
        const jsonString = JSON.stringify(filters);
        const encoded = encodeURIComponent(jsonString);
        return encoded;
    };

    const getEvents = async (encodeFilters) => {
        const res = await axios.get('/care/agenda/event-types', {
            params: {
                filters: encodeFilters,
            },
        });
        if (res.status === 200) {
            events.value = res.data.items;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const getEventTypeByEspecialistaDayWeek = async (especialistaId, dayWeek) => {
        const res = await axios.get(
            `/care/agenda/event-types/especialista/${especialistaId}/weekday/${dayWeek}`
        );
        if (res.status === 200) {
            events.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const updateEvent = async (id, data) => {
        const res = await axios.put(`/care/agenda/event-types/${id}`, data);
        if (res.status === 200) {
            // console.log(res.data);
            if (event.value) {
                event.value = res.data;
            }
            if (events.value) {
                events.value = events.value.map((e) => {
                    if (e.id === res.data.id) {
                        return res.data;
                    }
                    return e;
                });
            }
        } else {
            throw new Error(res.data.errors);
        }
    };

    const createEvent = async (data) => {
        const res = await axios.post('/care/agenda/event-types', data);
        if (res.status === 201) {
            // console.log(res.data);
            event.value = res.data;
            if (events.value) {
                events.value = [...events.value, res.data];
            }
        } else {
            throw new Error(res.data.errors);
        }
    };

    const removeEvent = async (id) => {
        const res = await axios.delete(`/care/agenda/event-types/${id}`);
        if (res.status === 200) {
            // console.log(res.data);
            event.value = undefined;
            if (events.value) {
                events.value = events.value.filter((e) => e.id !== id);
            }
        } else {
            throw new Error(res.data.errors);
        }
    };

    return {
        horas,
        event,
        events,
        getHoras,
        buildEvents,
        buildFilterForSetup,
        getEvents,
        createEvent,
        updateEvent,
        removeEvent,
        getEventTypeByEspecialistaDayWeek,
    };
}

export default useEventType;
