import { ref } from 'vue';
import axios from '@/services/useAxios';
import { DateTime } from 'luxon';

export function useIntervalDIsabled() {
    const intervalDisabled = ref(null);
    const intervalsDisabled = ref([]);

    const getIntervalDisabled = async (encodeFilters) => {
        const res = await axios.get('/care/agenda/interval-disabled', {
            params: {
                filters: encodeFilters,
            },
        });
        if (res.status === 200) {
            intervalsDisabled.value = res.data.items;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const getIntervalDisabledByEspecialistaWeekDay = async (especialista, day) => {
        const res = await axios.get(
            `/care/agenda/interval-disabled/especialista/${especialista}/date/${day}`
        );
        if (res.status === 200) {
            intervalsDisabled.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const buildFilters = (dateStart, dateEnd, weekDay = undefined, especialistaId = undefined) => {
        const filters = [];

        if (weekDay) {
            if (especialistaId) {
                // eslint-disable-next-line object-curly-newline
                filters.push({
                    field: 'especialista',
                    value: especialistaId,
                    condition: 'or',
                    equal: 'is',
                });
            }
            // eslint-disable-next-line object-curly-newline
            filters.push({ field: 'weekDay', value: weekDay, condition: 'or', equal: 'gte' });
            // eslint-disable-next-line object-curly-newline
            filters.push({ field: 'timeStart', value: dateStart, condition: 'and', equal: 'gte' });
            // eslint-disable-next-line object-curly-newline
            filters.push({ field: 'timeEnd', value: dateEnd, condition: 'and', equal: 'lte' });
        } else {
            if (especialistaId) {
                // eslint-disable-next-line object-curly-newline
                filters.push({
                    field: 'especialista',
                    value: especialistaId,
                    condition: 'or',
                    equal: 'is',
                });
            }
            // eslint-disable-next-line object-curly-newline
            filters.push({
                field: 'especialista',
                condition: 'or',
                equal: 'is',
            });
            if (dateStart) {
                // eslint-disable-next-line object-curly-newline
                filters.push({
                    field: 'timeStart',
                    value: dateStart,
                    condition: 'or',
                    equal: 'gte',
                });
            }
            if (dateEnd) {
                // eslint-disable-next-line object-curly-newline
                filters.push({ field: 'timeEnd', value: dateEnd, condition: 'or', equal: 'lte' });
            }
        }
        const jsonString = JSON.stringify(filters);
        const encoded = encodeURIComponent(jsonString);
        return encoded;
    };

    const buildFilterForSetup = (especialistaId) => {
        const filters = [];
        filters.push({
            field: 'weekDay',
            value: 0,
            condition: 'or',
            equal: 'gte',
        });
        filters.push({
            field: 'timeStart',
            value: DateTime.now().startOf('week').toMillis(),
            condition: 'or',
            equal: 'gte',
        });
        filters.push({
            field: 'especialista',
            value: especialistaId,
            condition: 'and',
            equal: 'is',
        });
        const jsonString = JSON.stringify(filters);
        const encoded = encodeURIComponent(jsonString);
        return encoded;
    };

    // eslint-disable-next-line object-curly-newline
    const buildIntervalDisable = ({ mode, date, day, timeStart, timeEnd, especialistaId }) => {
        // Un periodo de tiempo regular
        if (mode === 'periodico') {
            const ts = DateTime.fromISO(timeStart);
            const te = DateTime.fromISO(timeEnd).minus({ seconds: 1 });
            return {
                weekDay: day,
                startHour: ts.hour,
                startMin: ts.minute,
                startSec: ts.second,
                endHour: te.hour,
                endMin: te.minute,
                endSec: te.second,
                especialista: especialistaId,
            };
        }

        // Un Dia en especifico
        const ts = DateTime.fromISO(`${date.split('T')[0]}T${timeStart.split('T')[1]}`);
        const te = DateTime.fromISO(`${date.split('T')[0]}T${timeEnd.split('T')[1]}`).minus({
            seconds: 1,
        });
        return {
            timeStart: ts.toMillis(),
            timeEnd: te.toMillis(),
            especialista: especialistaId,
        };
    };

    const updateIntervalDisable = async (id, data) => {
        const res = await axios.put(`/care/agenda/interval-disabled/${id}`, data);
        if (res.status === 200) {
            // console.log(res.data);
            if (intervalDisabled.value) {
                intervalDisabled.value = res.data;
            }
            if (intervalsDisabled.value) {
                intervalsDisabled.value = intervalsDisabled.value.map((e) => {
                    if (e.id === res.data.id) {
                        return res.data;
                    }
                    return e;
                });
            }
        } else {
            throw new Error(res.data.errors);
        }
    };

    const createIntervalDisable = async (data) => {
        const res = await axios.post('/care/agenda/interval-disabled', data);
        if (res.status === 201) {
            // console.log(res.data);
            intervalDisabled.value = res.data;
            if (intervalsDisabled.value) {
                intervalsDisabled.value = [...intervalsDisabled.value, res.data];
            }
        } else {
            throw new Error(res.data.errors);
        }
    };

    const removeIntervalDisabled = async (id) => {
        const res = await axios.delete(`/care/agenda/interval-disabled/${id}`);
        if (res.status === 200) {
            // console.log(res.data);
            intervalDisabled.value = undefined;
            if (intervalsDisabled.value) {
                intervalsDisabled.value = intervalsDisabled.value.filter((e) => e.id !== id);
            }
        } else {
            throw new Error(res.data.errors);
        }
    };

    return {
        intervalDisabled,
        intervalsDisabled,
        getIntervalDisabled,
        getIntervalDisabledByEspecialistaWeekDay,
        buildFilters,
        buildFilterForSetup,
        buildIntervalDisable,
        updateIntervalDisable,
        createIntervalDisable,
        removeIntervalDisabled,
    };
}

export default useIntervalDIsabled;
