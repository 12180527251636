<template>
    <WindowLayout :title="title">
        <div class="row">
            <div class="col-12">
                <div class="darmin card">
                    <PacienteInfo v-model="state.paciente" :isReadOnly="state.id ? true : false"></PacienteInfo>
                </div>
            </div>
            <div class="col-12">
                <div class="darmin card">
                    <DoctoresInfo v-model="state.especialista" :isReadOnly="state.id ? true : false"></DoctoresInfo>
                </div>
            </div>
            <div class="col-sm-12">
                <div class="darmin card">
                    <div class="card-header pb-0 d-flex align-items-center">
                        <h5>{{ headerTitle }}</h5>
                    </div>
                    <div class="card-body theme-form">
                        <div class="row">
                            <div class="col">
                                <div class="mb-3 row">
                                    <label class="col-md-3 col-form-label">Motivo de Consulta</label>
                                    <div class="col-md-9" v-if="!state.id">
                                        <select class="form-select btn-square digits" v-model="state.motivo">
                                            <option disable></option>
                                            <option v-for="(o, i) in motivosconsultas" :key="i" :value="o.id">
                                                {{ o.name }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-9" v-else-if="state.motivo">
                                        <select class="form-select btn-square digits" v-model="state.motivo">
                                            <option disable></option>
                                            <option v-for="(o, i) in motivosconsultas" :key="i" :value="o.id">
                                                {{ o.name }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-9" v-else>
                                        <input type="text" disabled="true" :value="state.type" class="form-control" />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-md-3 col-form-label">Fecha</label>
                                    <div class="col-md-9">
                                        <DateInput v-model="fecha" :onlyRead="disabled"
                                            @onChage="handleSelectParamsToHorarios()" />
                                    </div>
                                </div>
                                <div class="mb-3 row" v-if="state.setmode === 1">
                                    <label class="col-sm-3 col-form-label">Horario</label>
                                    <div class="col-md-9">
                                        <select v-if="!disabled" class="form-select btn-square digits" :class="{
                                            'is-invalid':
                                                v.timeStart.$error || v.timeEnd.$error,
                                        }" v-model="state.timeStart" @change="setTimeToCita" :disabled="disabled">
                                            <option v-for="(f, i) in horariosDisponibles" :key="i" :value="f.timeStart">
                                                {{ f.title }}
                                            </option>
                                        </select>
                                        <span class="d-flex align-items-center h-100 ps-1" v-else>{{
                                            timeStartFormated
                                            }}</span>
                                        <div class="invalid-feedback" v-if="v.timeStart.$error || v.timeEnd.$error">
                                            {{ v.timeStart.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3 row" v-if="state.setmode === 2">
                                    <label class="col-md-3 col-form-label">Hora</label>
                                    <div class="col-md-9">
                                        <TimeInput v-model="tempTS" @onChange="setTimeToCitaFromInitdate"
                                            :disabled="!fecha || disabled" :class="{
                                                'is-invalid': isCrossin,
                                            }" />
                                        <div class="invalid-feedback" v-if="isCrossin">
                                            Este horario no esta disponible
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3 row" v-if="state.setmode === 2">
                                    <label class="col-md-3 col-form-label">Duracion (min)</label>
                                    <div class="col-md-9">
                                        <input type="number" class="form-control" min="10" max="60"
                                            v-model="tempDuration" @focus="$event.target.select()"
                                            @click="$event.target.select()" :disabled="disabled" />
                                    </div>
                                </div>
                                <div class="mb-3 row" v-if="state.id">
                                    <label class="col-sm-3 col-form-label">Estado</label>
                                    <div class="col-sm-9">
                                        <select class="form-select btn-square digits" v-model="state.status"
                                            :disabled="true">
                                            <option disable></option>
                                            <option value="1">Programada</option>
                                            <option value="2">En sala de espera</option>
                                            <option value="3">Finalizada</option>
                                            <option value="0">Cancelada</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <Observaciones :observaciones="state.observaciones" :disabled="disabled"
                    @onAddObservacion="handleAddNewObservacion" @onDeleteObservacion="handledeleteNewObservacion" />
            </div>
            <div class="col-12" v-if="state.id">
                <CitaModHistoryCard :history="history" />
            </div>
            <div class="col">
                <div class="darmin card header-card">
                    <div class="row">
                        <div class="col-12 text-end">
                            <div class="btn btn-outline-dark btn-default ms-3 mb-1" @click="handleAction('onWaiting')"
                                v-if="state.id && !disabled && state.status > 0 && isToday">
                                <i class="far fa-save"></i>
                                En Sala de Espera
                            </div>
                            <div class="btn btn-info ms-3 mb-1" @click="handleAction('crearHistoria')"
                                v-if="state.id && state.status > 1">
                                <i v-if="controlCita" class="far fa-file"></i>
                                <i v-else class="far fa-save"></i>
                                {{ controlCita ? "Ver Historia" : "Crear Historio" }}
                            </div>
                            <div class="btn btn-success ms-3 mb-1" @click="handleAction('finish')"
                                v-if="state.id && state.status > 1 && isToday">
                                <i class="far fa-save"></i>
                                Finalizar
                            </div>
                            <div class="btn btn-danger ms-3 mb-1" @click="handleAction('cancelar')"
                                v-if="state.id && state.status > 0">
                                <i class="fas fa-times"></i>
                                Cancelar
                            </div>
                            <div class="btn btn-primary ms-3 mb-1" @click="handleAction('save')"
                                v-if="state.id && !disabled && state.status > 0 && state.status < 3">
                                <i class="far fa-save"></i>
                                Guardar Cambios
                            </div>
                            <div v-if="!state.id" class="btn btn-primary ms-3 mb-1" @click="handleAction('new')">
                                <i class="far fa-save"></i>
                                Crear
                            </div>
                            <div v-if="!state.id" class="btn btn-primary ms-3 mb-1" @click="handleAction('new_view')">
                                <i class="far fa-save"></i>
                                Crear y mostrar
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <pre v-if="env === 'development'">{{ state }}</pre>
            <pre v-if="env === 'development'">disabled: {{ disabled }}</pre>
            <pre v-if="env === 'development'">{{ v.$errors }}</pre>
        </div>
    </WindowLayout>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { reactive, onMounted, computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { DateTime } from 'luxon';

import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

import { useSnackbar } from 'vue3-snackbar';
import WindowLayout from '@/layouts/Window.layout.vue';
import DateInput from '@/components/form/DateInput.vue';
import TimeInput from '@/components/form/TimeInput.vue';

import { useCita } from './services/useCita';
import { useCitas } from '../Agenda/services/useCitas';
import { useEventType } from '../Schedule/services/useEventType';
import { useIntervalDIsabled } from '../Agenda/services/useIntervalDisabled';
import { useControlCitas } from './services/useControlCitas';

import useExamen from '../Examenes/services/useExamen';
import useMotivosConsulta from '../MotivosConsulta/services/useMotivosConsulta';

import PacienteInfo from '../Pacientes/components/PacienteInfo.vue';
import DoctoresInfo from '../Doctores/components/DoctorInfo.vue';
import Observaciones from '../Components/Observaciones.vue';
import CitaModHistoryCard from './components/CitaModHistoryCard.vue';

export default {
    name: 'CitaForm',
    components: {
        WindowLayout,
        DoctoresInfo,
        DateInput,
        PacienteInfo,
        Observaciones,
        CitaModHistoryCard,
        TimeInput,
    },
    props: {
        title: { type: String, default: 'Cita' },
        headerTitle: { type: String, default: 'Ingresar Especialista' },
        item: Object,
        mode: { type: Number, default: 2 },
    },

    setup(props) {
        const disabled = ref(false);
        const store = useStore();
        const snackbar = useSnackbar();
        const controlCitasService = useControlCitas();
        const fecha = ref('');
        const tempTS = ref('');
        const tempDuration = ref(15);
        const state = reactive({
            id: '',
            timeStart: '',
            timeEnd: '',
            type: '',
            motivo: '',
            status: '1',
            class: 'event',
            especialista: '',
            paciente: '',
            observaciones: [],
            setmode: props.mode,
        });
        const isLoadingControl = ref(true);
        const controlCita = ref(undefined);
        const history = ref([]);

        const { citas, getCitas, buildFilters: CitasFilter } = useCitas();
        // eslint-disable-next-line object-curly-newline
        const { cita, createCita, getCita, updateCita } = useCita();
        const { intervalsDisabled, getIntervalDisabledByEspecialistaWeekDay } =
            useIntervalDIsabled();
        // eslint-disable-next-line object-curly-newline
        const { events, horas, getHoras, getEventTypeByEspecialistaDayWeek } = useEventType();
        const { examenes, getExamenes } = useExamen();
        const { motivosconsultas, getMotivosconsultas } = useMotivosConsulta();

        /** ***** Helpers ***** */
        const handleSelectParamsToHorarios = async () => {
            // console.log('Changeeeeee');
            if (fecha.value && state.especialista) {
                // console.log('weekday', fecha.value, DateTime.fromISO(fecha.value));
                const day = DateTime.fromISO(fecha.value);
                const endOfDay = day.plus({ hours: 23, minutes: 59, seconds: 59 });
                // const { weekday } = DateTime.fromISO(fecha.value);
                await Promise.all([
                    getEventTypeByEspecialistaDayWeek(state.especialista, day.weekday),
                    getIntervalDisabledByEspecialistaWeekDay(state.especialista, day.toMillis()),
                    getHoras(day.weekday, state.especialista),
                    getCitas(
                        CitasFilter(
                            day.toMillis(),
                            endOfDay.toMillis(),
                            undefined,
                            // eslint-disable-next-line comma-dangle
                            state.especialista
                            // eslint-disable-next-line comma-dangle
                        )
                    ),
                ]);
            }
        };

        const handleAddNewObservacion = (data) => {
            state.observaciones.push(data.observacion);
        };

        const handledeleteNewObservacion = (data) => {
            console.log('data.index', data.index);
            state.observaciones = state.observaciones.filter((o, i) => {
                console.log('i', i);
                return i !== data.index;
            });
        };

        const horariosDisponibles = computed(() => {
            // console.log('horariosDisponibles');
            const day = DateTime.fromISO(fecha.value);
            const horarioss = [];
            horas.value.forEach((s) => {
                const o = {};
                o.timeStart = DateTime.fromObject({
                    ...day.toObject(),
                    hour: s.startHour,
                    minute: s.startMin,
                    second: s.startSec,
                });

                o.timeEnd = DateTime.fromObject({
                    ...day.toObject(),
                    hour: s.endHour,
                    minute: s.endMin,
                    second: s.endSec,
                }).toMillis();
                const selfDate = o.timeStart.toMillis() === state.timeStart ? '<-' : '';

                o.title = `${o.timeStart.toFormat('h:mm a')} ${selfDate}`;
                o.timeStart = o.timeStart.toMillis();
                if (
                    citas.value.find(
                        // eslint-disable-next-line comma-dangle
                        (c) =>
                            // eslint-disable-next-line operator-linebreak, implicit-arrow-linebreak
                            o.timeStart === DateTime.fromISO(c.timeStart).toMillis() &&
                            // eslint-disable-next-line comma-dangle
                            o.timeStart !== state.timeStart
                    ) === undefined
                ) {
                    horarioss.push(o);
                }
            });

            return horarioss;
        });

        const setTimeToCita = () => {
            const f = horariosDisponibles.value.find((h) => h.timeStart === state.timeStart);
            state.timeEnd = f.timeEnd;
        };

        const setTimeToCitaFromInitdate = () => {
            // console.log('fecha.value', fecha.value);
            const day = DateTime.fromISO(fecha.value);
            // console.log(day);
            const time = DateTime.fromISO(tempTS.value);

            // console.log(day.year);
            // console.log(day.month);
            // console.log(day.day);
            // console.log(time.hour);
            // console.log(time.minute);
            // console.log(time.second);

            const starTime = DateTime.fromObject({
                year: day.year,
                month: day.month,
                day: day.day,
                hour: time.hour,
                minute: time.minute,
                second: time.second,
            });
            // console.log(starTime);
            state.timeStart = starTime.toMillis();
            state.timeEnd = starTime.plus({ minutes: tempDuration.value }).toMillis() - 1000;
        };

        const isToday = computed(() => {
            if (DateTime.now().hasSame(DateTime.fromMillis(Number(state.timeStart)), 'day')) {
                return true;
            }
            return false;
        });

        watch(
            () => fecha.value,
            async () => {
                await handleSelectParamsToHorarios();
                setTimeToCitaFromInitdate();
            }
        );

        watch(
            () => state.especialista,
            async () => {
                await handleSelectParamsToHorarios();
                setTimeToCitaFromInitdate();
            }
        );

        watch(
            () => state.motivo,
            (n) => {
                if (n) state.type = motivosconsultas.value.find((m) => m.id === n).type;
                // eslint-disable-next-line comma-dangle
            }
        );

        const temoDurationFlag = ref();
        watch(
            () => tempDuration.value,
            (n, o) => {
                if (!disabled.value) {
                    if (n < 10 || n > 60) {
                        if (temoDurationFlag.value) {
                            clearInterval(temoDurationFlag.value);
                            temoDurationFlag.value = undefined;
                        }
                        temoDurationFlag.value = setTimeout(() => {
                            if (o < 10) {
                                tempDuration.value = 15;
                            } else if (o > 60) {
                                tempDuration.value = 60;
                            } else {
                                tempDuration.value = o;
                            }
                            temoDurationFlag.value = undefined;
                        }, 400);
                    } else {
                        clearInterval(temoDurationFlag.value);
                        temoDurationFlag.value = undefined;
                    }
                    setTimeToCitaFromInitdate();
                }
            }
        );

        const isCrossin = computed(() => {
            if (fecha.value && state.timeStart && state.timeEnd && state.especialista) {
                const day = DateTime.fromISO(fecha.value);
                const f = events.value.find((e) => {
                    // console.log('w', e.schedule[0].startHour);
                    const starTime = DateTime.fromObject({
                        year: day.year,
                        month: day.month,
                        day: day.day,
                        hour: e.schedule[0].startHour,
                        minute: e.schedule[0].startMin,
                        second: e.schedule[0].startSec,
                    }).toMillis();
                    const endTime = DateTime.fromObject({
                        year: day.year,
                        month: day.month,
                        day: day.day,
                        hour: e.schedule[0].endHour,
                        minute: e.schedule[0].endMin,
                        second: e.schedule[0].endSec,
                    }).toMillis();
                    // console.log('e', starTime);
                    // console.log('e', state.timeStart);
                    // console.log('e', endTime);
                    if (starTime <= state.timeStart && endTime >= state.timeStart) {
                        // console.log('se encontro event');
                        // console.log(e);
                        // console.log(state.type);
                        if (e.types.includes(state.type)) {
                            return true;
                        }
                    }
                    return false;
                });

                const g = citas.value.find((c) => {
                    if (!state.id || state.id !== c.id) {
                        const starTime = DateTime.fromISO(c.timeStart).toMillis();
                        const endTime = DateTime.fromISO(c.timeEnd).toMillis();
                        if (c.id !== state.id) {
                            if (
                                (state.timeStart >= starTime && state.timeStart <= endTime) ||
                                (state.timeEnd >= starTime && state.timeEnd <= endTime)
                            ) {
                                // console.log('se cruza con cita');
                                // console.log('c', c.timeStart, c.timeEnd);
                                return true;
                            }
                        }
                    }
                    return false;
                });

                const h = intervalsDisabled.value.find((id) => {
                    if (id.timeStart) {
                        const starTime = DateTime.fromISO(id.timeStart).toMillis();
                        const endTime = DateTime.fromISO(id.timeEnd).toMillis();
                        if (
                            (state.timeStart >= starTime && state.timeStart <= endTime) ||
                            (state.timeEnd >= starTime && state.timeEnd <= endTime)
                        ) {
                            // console.log('se cruza con un disabled con fecha');
                            // console.log(id);
                            return true;
                        }
                    } else {
                        const starTime = DateTime.fromObject({
                            year: day.year,
                            month: day.month,
                            day: day.day,
                            hour: id.startHour,
                            minute: id.startMin,
                            second: id.startSec,
                        }).toMillis();
                        const endTime = DateTime.fromObject({
                            year: day.year,
                            month: day.month,
                            day: day.day,
                            hour: id.endHour,
                            minute: id.endMin,
                            second: id.endSec,
                        }).toMillis();

                        if (
                            (state.timeStart >= starTime && state.timeStart <= endTime) ||
                            (state.timeEnd >= starTime && state.timeEnd <= endTime)
                        ) {
                            // console.log('se cruza con un disabled con weekday');
                            // console.log(id);
                            return true;
                        }
                    }
                    return false;
                });
                console.log(!f, !!g, !!h);
                if (!f || g || h) {
                    return true;
                }
            }
            return false;
        });

        const timeStartFormated = computed(
            () =>
                // eslint-disable-next-line comma-dangle, implicit-arrow-linebreak
                DateTime.fromMillis(state.timeStart).toFormat('h:mm a')
            // eslint-disable-next-line function-paren-newline
        );

        const motivoConsultaSelected = computed(() => motivosconsultas.value.find((m) => m.id === state.motivo));

        /** ***** Lifecycle Hooks ***** */
        const setState = async () => {
            if (cita.value) {
                state.id = cita.value.id;
                state.timeStart = DateTime.fromISO(cita.value.timeStart).toMillis();
                state.timeEnd = DateTime.fromISO(cita.value.timeEnd).toMillis();
                tempTS.value = cita.value.timeStart;
                tempDuration.value = Math.ceil((state.timeEnd - state.timeStart) / 60000);
                state.type = cita.value.type;
                state.motivo = cita.value.motivo?.id;
                state.status = cita.value.status;
                state.class = cita.value.class;
                state.paciente = cita.value.paciente.id;
                state.especialista = cita.value.especialista?.id;
                state.observaciones = cita.value.observaciones;
                state.setmode = cita.value.setmode || 1;

                fecha.value = DateTime.fromISO(cita.value.timeStart).toISO();
                disabled.value = state.timeStart <= DateTime.now().toMillis();

                history.value = cita.value.history;
                await handleSelectParamsToHorarios();
            }
        };

        const resetWindow = () => {
            state.id = '';
            state.timeStart = '';
            state.timeEnd = '';
            state.type = '';
            state.motivo = '';
            state.status = '1';
            state.class = 'event';
            state.paciente = '';
            state.especialista = '';
            state.observaciones = [];
            state.setmode = 1;

            fecha.value = '';

            disabled.value = false;

            history.value = [];
        };

        onMounted(async () => {
            await getMotivosconsultas();
            if (props.item) {
                await Promise.all([getCita(props.item.id), getExamenes()]);
                if (cita.value) {
                    setState();
                    controlCitasService.getByCita(cita.value.id).then((r) => {
                        controlCita.value = r;
                        isLoadingControl.value = false;
                    }).catch(() => {
                        isLoadingControl.value = false;
                    });
                }
            }
        });

        /** ***** Validator ***** */

        const motivoValidator = (value) => {
            if (state.setmode === 2) {
                if (value) {
                    return { $valid: true };
                }
                return { $valid: false, message: 'Este campo es requerido', extraParams: {} };
            }
            return { $valid: true };
        };

        const rules = computed(() => ({
            especialista: { required },
            timeStart: { required },
            timeEnd: { required },
            motivo: { motivoValidator },
            paciente: { required },
            status: { required },
        }));
        const $v = useVuelidate(rules, state);

        const handleAction = async (action) => {
            await $v.value.$validate();
            if (!$v.value.$error) {
                // No errors
                let text;
                switch (action) {
                    case 'new':
                    case 'new_view':
                        await createCita({
                            timeStart: state.timeStart,
                            timeEnd: state.timeEnd,
                            type: state.type,
                            motivo: state.motivo,
                            status: state.status,
                            class: state.class,
                            paciente: state.paciente,
                            especialista: state.especialista,
                            observaciones: state.observaciones,
                            setmode: state.setmode,
                        });
                        if (action === 'new_view') {
                            store.dispatch('ContentManager/openWindow', {
                                id: `CitaForm_${cita.value.id}`,
                                component: 'CitaForm',
                                name: 'Cita programada',
                                params: {
                                    title: 'Cita programada',
                                    headerTitle: 'Editar Cita',
                                    item: { ...cita.value },
                                },
                            });
                        }
                        $v.value.$reset();
                        resetWindow();
                        text = 'Cita programada';
                        break;
                    case 'save':
                        await updateCita(state.id, {
                            timeStart: state.timeStart,
                            timeEnd: state.timeEnd,
                            type: state.type,
                            motivo: state.motivo,
                            status: state.status,
                            class: state.class,
                            paciente: state.paciente,
                            especialista: state.especialista,
                            observaciones: state.observaciones,
                            setmode: state.setmode,
                        });
                        text = 'Cita actualizada';
                        setState();
                        break;
                    case 'cancelar':
                        await updateCita(state.id, {
                            timeStart: state.timeStart,
                            timeEnd: state.timeEnd,
                            type: state.type,
                            motivo: state.motivo,
                            status: 0,
                            class: state.class,
                            paciente: state.paciente,
                            especialista: state.especialista,
                            observaciones: state.observaciones,
                            setmode: state.setmode,
                        });
                        text = 'Cita actualizada';
                        setState();
                        break;
                    case 'onWaiting':
                        await updateCita(state.id, {
                            timeStart: state.timeStart,
                            timeEnd: state.timeEnd,
                            type: state.type,
                            motivo: state.motivo,
                            status: 2,
                            class: state.class,
                            paciente: state.paciente,
                            especialista: state.especialista,
                            observaciones: state.observaciones,
                            setmode: state.setmode,
                        });
                        text = 'Cita actualizada';
                        setState();
                        break;
                    case 'finish':
                        await updateCita(state.id, {
                            timeStart: state.timeStart,
                            timeEnd: state.timeEnd,
                            type: state.type,
                            motivo: state.motivo,
                            status: 3,
                            class: state.class,
                            paciente: state.paciente,
                            especialista: state.especialista,
                            observaciones: state.observaciones,
                            setmode: state.setmode,
                        });
                        text = 'Cita actualizada';
                        setState();
                        break;
                    case 'crearHistoria':
                        if (controlCita.value) {
                            store.dispatch('ContentManager/openWindow', {
                                id: `ConsultaOftalmologiaForm_${controlCita.value.consulta}`,
                                component: 'ConsultaOftalmologiaForm',
                                name: 'Consulta',
                                params: {
                                    name: 'Name Consulta',
                                    headerTitle: 'Header Consulta',
                                    item: { _id: controlCita.value.consulta, id: controlCita.value.consulta }
                                },
                            });
                        } else {
                            store.dispatch('ContentManager/openWindow', {
                                id: `AsistenteDeConsulta_${Date.now()}`,
                                component: 'AsistenteDeConsulta',
                                name: 'Nueva Consulta',
                                params: {
                                    name: 'Nueva Consulta',
                                    headerTitle: 'Nueva Consulta',
                                    item: { cita: state.id, paciente: { id: state.paciente }, formType: motivoConsultaSelected.value.formType },
                                },
                            });
                        }
                        break;
                    default:
                        break;
                }
                if (text) {
                    snackbar.add({
                        type: 'success',
                        text,
                    });
                }
            } else {
                console.log($v.value.$errors);
                snackbar.add({
                    type: 'error',
                    text: 'Ups no podemos enviar la informacion sin algunos datos',
                });
            }
        };

        return {
            v: $v,
            state,
            history,
            fecha,
            disabled,
            timeStartFormated,
            examenes,
            /** Actions */
            handleAction,
            setTimeToCita,
            /** Others */
            handleSelectParamsToHorarios,
            handleAddNewObservacion,
            handledeleteNewObservacion,
            horariosDisponibles,
            isToday,
            env: process.env.NODE_ENV,
            motivosconsultas,
            tempTS,
            setTimeToCitaFromInitdate,
            tempDuration,
            isCrossin,
            isLoadingControl,
            controlCita,
        };
    },
};
</script>

<style></style>
