import axios from '@/services/useAxios';

export function useControlCitas() {
    const getByCita = async (id) => {
        const res = await axios.get(`/care/control-citas/cita/${id}`);
        if (res.status === 200) {
            // console.log(res.data);
            return res.data.controlCita;
        }
        throw new Error(res.data.errors);
    };

    return {
        getByCita,
    };
}

export default useControlCitas;
