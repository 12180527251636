<template>
    <div class="darmin card">
        <div
            class="card-header pb-0 d-flex align-items-center justify-content-between collapsed"
            data-bs-toggle="collapse"
            :data-bs-target="`#card-${ramId}`"
            :aria-expanded="true"
            :aria-controls="`card-${ramId}`"
        >
            <h5>Historial de Cambios</h5>
            <i class="fas fa-angle-up"></i>
        </div>
        <div class="card-body theme-form">
            <div class="collapse" :id="`card-${ramId}`">
                <CitaModHistoryRow v-for="h in history" :key="h._id" :history="h" />
            </div>
        </div>
    </div>
</template>
<script>
import { ref } from 'vue';
import CitaModHistoryRow from './CitaModHistoryRow.vue';

export default {
    name: 'CitaModHistoryCard',
    components: { CitaModHistoryRow },
    props: { history: Array },
    setup() {
        const ramId = ref(Math.floor(Math.random() * 1000));
        return { ramId };
    },
};
</script>
<style lang="scss" scoped></style>
