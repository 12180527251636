import { ref } from 'vue';
import axios from '@/services/useAxios';

export function useCitas() {
    const citas = ref([]);

    const getCitas = async (encodeFilters, limit = 30, page = 1) => {
        const res = await axios.get('/care/agenda/citas', {
            params: {
                filters: encodeFilters,
                limit,
                page,
            },
        });
        if (res.status === 200) {
            citas.value = res.data.items.map((e) => {
                e.isOpen = false;
                return e;
            });
        } else {
            throw new Error(res.data.errors);
        }
    };

    const buildFilters = (dateStart, dateEnd, weekDay = undefined, especialistaId = undefined) => {
        const filters = [];

        if (weekDay) {
            if (especialistaId) {
                // eslint-disable-next-line object-curly-newline
                filters.push({
                    field: 'especialista',
                    value: especialistaId,
                    condition: 'or',
                    equal: 'is',
                });
            }
            // eslint-disable-next-line object-curly-newline
            filters.push({ field: 'weekDay', value: weekDay, condition: 'or', equal: 'gte' });
            // eslint-disable-next-line object-curly-newline
            filters.push({ field: 'timeStart', value: dateStart, condition: 'and', equal: 'gte' });
            // eslint-disable-next-line object-curly-newline
            filters.push({ field: 'timeEnd', value: dateEnd, condition: 'and', equal: 'lte' });
            // eslint-disable-next-line object-curly-newline
            filters.push({ field: 'status', value: 0, condition: 'and', equal: 'gt' });
        } else {
            if (especialistaId) {
                // eslint-disable-next-line object-curly-newline
                filters.push({
                    field: 'especialista',
                    value: especialistaId,
                    condition: 'or',
                    equal: 'is',
                });
            }
            // eslint-disable-next-line object-curly-newline
            filters.push({ field: 'timeStart', value: dateStart, condition: 'and', equal: 'gte' });
            // eslint-disable-next-line object-curly-newline
            filters.push({ field: 'timeStart', value: dateEnd, condition: 'and', equal: 'lte' });
            // eslint-disable-next-line object-curly-newline
            filters.push({ field: 'status', value: 0, condition: 'and', equal: 'gt' });
        }
        const jsonString = JSON.stringify(filters);
        const encoded = encodeURIComponent(jsonString);
        return encoded;
    };

    return { citas, buildFilters, getCitas };
}

export default useCitas;
