import { ref } from 'vue';
import axios from '@/services/useAxios';

export function useCita() {
    const cita = ref(null);
    const citas = ref([]);

    const createCita = async (data) => {
        const res = await axios.post('/care/agenda/citas', data);
        if (res.status === 201) {
            // console.log(res.data);
            cita.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const getCita = async (id) => {
        const res = await axios.get(`/care/agenda/citas/${id}`);
        if (res.status === 200) {
            // console.log(res.data);
            cita.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const getCitas = async (searchQuery = '') => {
        const query = searchQuery ? `?searchQuery=${searchQuery}` : '';

        const res = await axios.get(`/care/agenda/citas${query}`);

        if (res.status === 200) {
            citas.value = res.data.items;
        }
    };

    const updateCita = async (id, data) => {
        const res = await axios.put(`/care/agenda/citas/${id}`, data);
        if (res.status === 200) {
            // console.log(res.data);
            cita.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const onWaiting = async (id) => {
        const res = await axios.put(`/care/agenda/citas/on-waiting-room/${id}`);
        if (res.status === 200) {
            cita.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const finish = async (id) => {
        const res = await axios.put(`/care/agenda/citas/finish/${id}`);
        if (res.status === 200) {
            cita.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const upgradeStatus = async (id) => {
        const res = await axios.put(`/care/agenda/citas/status/up/${id}`);
        if (res.status === 200) {
            cita.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const downgradeStatus = async (id) => {
        const res = await axios.put(`/care/agenda/citas/status/down/${id}`);
        if (res.status === 200) {
            cita.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const cancelCita = async (id) => {
        const res = await axios.put(`/care/agenda/citas/cancel/${id}`);
        if (res.status === 200) {
            cita.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    return {
        cita,
        citas,
        createCita,
        getCita,
        getCitas,
        updateCita,
        cancelCita,
        onWaiting,
        finish,
        upgradeStatus,
        downgradeStatus,
    };
}

export default useCita;
